<template>
  <div class="d-inline-block bg-white text-nowrap pr-2">
    <span class="px-2">
      {{ averageRounded }}
    </span>
    <img
      v-for="(star, index) in starAmount"
      :key="index"
      :src="star"
      class="star"
    />
    <span v-if="count" class="pl-2">({{ count }})</span>
  </div>
</template>

<script>
import star_full from '@gid/vue-common/assets/images/star_full.svg';
import star_empty from '@gid/vue-common/assets/images/star_empty.svg';
export default {
  props: {
    count: Number,
    average: Number,
  },
  computed: {
    averageRounded() {
      if (!this.average) return '-';
      return Number(this.average).toFixed(1);
    },
    starAmount() {
      const rounded = Math.round(this.average);
      const result = [];
      for (let i = 0; i < 5; i++) {
        result.push(rounded >= i + 1 ? star_full : star_empty);
      }
      return result;
    },
  },
};
</script>

<style scoped>
.star {
  width: 1rem;
  margin-top: -0.3em;
}
</style>
