<template>
  <multiselect
    :searchable="true"
    :options="options"
    label="label"
    track-by="value"
    v-model="value"
    placeholder="Select Watcher"
    :multiple="true"
    :group-select="true"
    group-values="watchers"
    group-label="select_all"
  ></multiselect>
</template>
<script>
import axios from 'axios';
export default {
  created() {
    this.fetchWatchers();
  },
  data() {
    return {
      value: [],
      options: [
        {
          select_all: 'All watched partners',
          watchers: [],
        },
      ],
    };
  },
  methods: {
    fetchWatchers() {
      return axios
        .get(`/data-api/partner-watchlist/watchers`)
        .then((response) => {
          this.options[0].watchers = response.data.map((watcher) => ({
            label: watcher.admin.name,
            value: watcher.adminId,
          }));
        })
        .catch((error) => {
          console.error(error);
          this.$bvToast.toast(`Error fetching watchers list`, {
            title: 'Error',
            variant: 'danger',
            solid: true,
            noAutoHide: true,
          });
        });
    },
  },
  watch: {
    value(newValue) {
      this.$emit(
        'input',
        newValue.map((val) => val.value),
      );
    },
  },
};
</script>
