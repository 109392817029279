<template>
  <div class="d-inline-block text-nowrap">
    <img
      v-for="(star, index) in starAmount"
      :key="index"
      :src="star"
      class="star"
      @click="$emit('input', index + 1)"
      @mouseenter="hover = index + 1"
      @mouseleave="hover = null"
    />
  </div>
</template>

<script>
import star_full from '@gid/vue-common/assets/images/star_full.svg';
import star_empty from '@gid/vue-common/assets/images/star_empty.svg';
export default {
  props: {
    value: Number,
  },
  data() {
    return {
      hover: null,
    };
  },
  computed: {
    starAmount() {
      const result = [];
      for (let i = 0; i < 5; i++) {
        const activeIndex = this.hover ?? this.value;
        const isFull = activeIndex >= i + 1;
        result.push(isFull ? star_full : star_empty);
      }
      return result;
    },
  },
};
</script>

<style scoped>
.star {
  width: 1.2rem;
  margin-top: -0.3em;
  margin-right: 0.2rem;
  cursor: pointer;
}
</style>
