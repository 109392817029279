<template>
  <div>
    <b-spinner v-if="inProgress" />
    <div v-else>
      <table class="table">
        <thead>
          <tr>
            <th>Field</th>
            <th>Parnter</th>
          </tr>
          <tr>
            <th></th>
            <th>
              <router-link
                :to="`/data-ui/Account/${data.account_partner_id}`"
                target="_blank"
                rel="noreferrer"
              >
                Partner Record in Data UI
              </router-link>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cfg, field) in partnerFields" :key="field">
            <td>{{ cfg.label }}</td>
            <td>
              <multiselect
                :multiple="true"
                v-model="data[`account_${field}`]"
                :options="cfg.picklistValues"
              />
            </td>
          </tr>

          <tr>
            <td>Partner Actions</td>
            <td>
              <button
                type="button"
                class="btn btn-secondary"
                @click="savePartnerDetails()"
              >
                <i class="material-icons">save</i>
                Save Qualifications
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    partner: Object,
  },
  watch: {
    partner() {
      this.reloadAll();
    },
  },
  data() {
    return {
      partnerFields: {
        hwo_a: {
          label: 'HwO A',
          values: [],
          picklistApiName: 'HwO_A',
          picklistValues: [],
        },
        hwo_b1: {
          label: 'HwO B1',
          values: [],
          picklistApiName: 'HwO_B1',
          picklistValues: [],
        },
        hwo_b2: {
          label: 'HwO B2',
          values: [],
          picklistApiName: 'HwO_B2',
          picklistValues: [],
        },
        kein_hwo: {
          label: 'Kein HwO',
          values: [],
          picklistApiName: 'nicht_HwO',
          picklistValues: [],
        },
        zusatzqualifikation: {
          label: 'Zusatzqualifikation',
          values: [],
          picklistApiName: 'Zertifikat_Zusatzqualifikation',
          picklistValues: [],
        },
        weiterbildung: {
          label: 'Brand Training',
          values: [],
          picklistApiName: 'Weiterbildung',
          picklistValues: [],
        },
        transporter: {
          label: 'Transporter',
          values: [],
          picklistApiName: 'Transporter',
          picklistValues: [],
        },
      },
      data: {},
      inProgress: null,
    };
  },
  methods: {
    savePartnerDetails() {
      const newValues = {};
      for (const [field] of Object.entries(this.partnerFields)) {
        newValues[field] = this.data[`account_${field}`];
      }
      newValues['id'] = this.partner.account.id;
      this.inProgress = true;
      axios
        .post('/api/admin/partner/qualifications/', newValues)
        .then(() => {
          return this.reloadData();
        })
        .catch((error) => {
          const data = error.response.data || error.response || error;
          const msg = data.error || data.errors || data;
          alert(JSON.stringify(msg));
          this.inProgress = false;
        });
    },
    reloadData() {
      this.inProgress = true;
      this.reloadDataQuery().then(() => {
        this.inProgress = false;
      });
    },
    reloadDataQuery() {
      return Promise.all([
        axios
          .get(`/api/admin/partner/${this.partner.account.id}/qualifications`)
          .then((response) => {
            const qualificaitons = {};
            Object.keys(response.data).forEach((field) => {
              qualificaitons[`account_${field}`] = response.data[field];
            });
            this.data = qualificaitons;
          })
          .catch((error) => {
            throw error;
          }),
      ]);
    },
    loadPicklistValues() {
      return axios
        .get('/data-api/sf-picklist', {
          params: {
            api_names: Object.keys(this.partnerFields).map(
              (picklist) => this.partnerFields[picklist].picklistApiName,
            ),
            values_only: true,
          },
        })
        .then((response) => {
          for (const fieldCfg of Object.values(this.partnerFields)) {
            const picklist = response.data[fieldCfg.picklistApiName];
            if (picklist) {
              fieldCfg.picklistValues = picklist.Metadata.customValue.map(
                (item) => item.valueName,
              );
            }
          }
        });
    },
    reloadAll() {
      this.inProgress = true;
      Promise.all([this.reloadDataQuery(), this.loadPicklistValues()]).then(
        () => {
          this.inProgress = false;
        },
      );
    },
  },
  async created() {
    this.reloadAll();
  },
};
</script>
