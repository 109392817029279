<template>
  <b-modal
    id="accept-job-modal"
    :visible="visible"
    size="sm"
    hide-footer
    centered
    no-close-on-backdrop
    no-close-on-esc
    @close="onModalClose"
  >
    <div class="d-block text-center">
      <h5 class="mb-4 mt-2">{{ $t('modals.accept-job.title') }}</h5>
      <b-row class="justify-content-center">
        <b-col cols="5">
          <b-button class="w-100" variant="primary" @click="onAcceptJob">
            {{ $t('yes') }}
          </b-button>
        </b-col>
        <b-col cols="5">
          <b-button class="w-100" variant="primary" @click="onModalClose">
            {{ $t('no') }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'AcceptJobModal',
  props: {
    visible: Boolean,
  },
  methods: {
    onAcceptJob() {
      this.$emit('close');
    },
    onModalClose() {
      this.$emit('close');
    },
  },
};
</script>
