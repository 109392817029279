
import PartnerOffers from './PartnerOffers.vue';
import PartnerQualifications from './PartnerQualifications.vue';
import RejectedJobs from './RejectedJobs.vue';
import Accounting from './PartnerAccounting.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    PartnerOffers,
    PartnerQualifications,
    RejectedJobs,
    Accounting,
  },
  props: {
    partner: Object,
  },
});
