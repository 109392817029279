<template>
  <div>
    <PartnerJobsModal
      v-model="showJobPartnerModal"
      :partnerId="partner.id"
      :partnerName="partner.name"
      :contacts="partner.contacts"
      :completedJobs="partner.completedJobs"
      :activeTab="activeTab"
      @close="toggleContactsModal"
    />
    <PartnerReachedModal
      :visible="showPartnerReachedModal"
      :jobIds="selectedPartnerJobIds"
      @close="closePartnerReachedModal"
    />
  </div>
</template>

<script>
import axios from 'axios';
import PartnerJobsModal from '@/components/partner/PartnerContactJobsModal.vue';
import PartnerReachedModal from '@/components/partner/PartnerReachedModal.vue';

export default {
  components: {
    PartnerJobsModal,
    PartnerReachedModal,
  },
  props: {
    partner: {
      type: Object,
      default: () => {
        return {
          id: '',
          name: '',
          contacts: [],
          completedJobs: 0,
        };
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: String,
      default: 'shown',
    },
  },
  data() {
    return {
      showJobPartnerModal: false,
      showPartnerReachedModal: false,
      selectedPartnerJobIds: [],
    };
  },
  watch: {
    show: function (showJobsModal) {
      // watch it
      if (showJobsModal) {
        this.showJobPartnerModal = true;
      }
    },
  },
  methods: {
    toggleContactsModal({ selectedJobsIds, isCalled }) {
      this.showJobPartnerModal = !this.showJobPartnerModal;
      // selectJobsIds passed we now that PartnerContactsModal is closed
      // & user select jobs, so we will show PartnerReached modal
      if (selectedJobsIds && selectedJobsIds.length && isCalled) {
        this.showPartnerReachedModal = !this.showPartnerReachedModal;
        this.selectedPartnerJobIds = selectedJobsIds;
      } else {
        this.closePartnerReachedModal();
      }
    },
    closePartnerReachedModal() {
      this.showPartnerReachedModal = false;
      this.$emit('close');
    },
  },
};
</script>
