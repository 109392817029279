<template>
  <b-modal
    :visible="visible"
    size="sm"
    hide-footer
    hide-header
    centered
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="d-block text-center">
      <h5 class="mb-4 mt-2">{{ $t('partner-available-title') }}</h5>
      <div>
        <b-button variant="success" @click="onPartnerReached(true)">
          {{ $t('yes') }}
        </b-button>
        <b-button
          class="ml-3"
          variant="danger"
          @click="onPartnerReached(false)"
        >
          {{ $t('no') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios';
import { UserRoleEnum } from '@gid/models';

export default {
  name: 'PartnerReachedModal',
  props: {
    visible: Boolean,
    jobIds: Array,
  },
  methods: {
    async onPartnerReached(isPartnerReached) {
      if (!isPartnerReached) {
        await this.createPartnerAvailableComment();
      }
      this.$emit('close');
    },
    /** Make a comments on all selected job, that partner can not be reached  **/
    async createPartnerAvailableComment() {
      const partnerUnavailableComments = this.jobIds.map((jobApiId) => {
        return {
          actionType: 'generic',
          jobApiId,
          message: this.$t('call.partner-unavailable'),
          visibleForRoles: [UserRoleEnum.ADMIN, UserRoleEnum.BRAND],
        };
      });
      try {
        await axios.post(`data-api/comment/bulk`, {
          bulk: partnerUnavailableComments,
        });
      } catch (error) {
        this.$bvToast.toast(error, {
          title: 'Error',
          variant: 'danger',
          noAutoHide: true,
        });
      }
    },
  },
};
</script>
