<template>
  <div>
    <b-button variant="primary" class="mb-2" @click="openRejectionModal">
      {{ $t('partner.reject.add') }}
    </b-button>
    <b-modal
      ref="addRejectionModal"
      :title="$t('partner.reject.add')"
      centered
      :ok-title="$t('partner.reject.button')"
      :ok-disabled="rejectReason === null"
      @ok="rejectJobs"
    >
      <p class="mb-2">{{ $t('partner.reject.select-jobs') }}:</p>
      <multiselect
        class="mb-2"
        v-model="job.selected"
        track-by="value"
        label="name"
        :placeholder="$t('type-to-search')"
        :multiple="true"
        :options="job.options"
        :internal-search="false"
        :show-no-results="false"
        :loading="job.loading"
        @search-change="jobsFind"
      />
      <p v-if="job.error" class="text-danger">
        <small>{{ $t('partner.reject.error-fetching-jobs') }}</small>
      </p>
      <b-form-select
        v-model="rejectReason"
        :options="rejectReasons"
        class="mb-2"
      >
        <template slot="first">
          <option :value="null" disabled>
            -- {{ $t('partner.reject.select-placeholder') }} --
          </option>
        </template>
      </b-form-select>
      <b-form-textarea
        v-if="useRejectReasonText"
        v-model="rejectReasonText"
        :placeholder="$t('partner.reject.text-placeholder')"
        rows="3"
        max-rows="6"
        class="mb-2"
      ></b-form-textarea>
    </b-modal>
    <b-table
      show-empty
      stacked="md"
      ref="rejectionsTable"
      :items="itemsProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDir"
      :busy.sync="loading"
    >
      <template #cell(job_name)="{ item, value }">
        <router-link :to="`/jobs/${item.job}`">{{ value }}</router-link>
      </template>
      <template #cell(reason)="{ item, value }">
        <span v-if="value == 'other_reason'">{{ item.reason_text }}</span>
        <span v-else>{{ value }}</span>
      </template>
      <template #cell(_actions)="{ item }">
        <b-button size="sm" @click="removeRejection(item.id)">
          {{ $t('partner.reject.remove.button') }}
        </b-button>
      </template>
    </b-table>
    <b-spinner variant="secondary" v-if="loading" />
    <b-pagination
      v-if="showPagination"
      :total-rows="totalRows"
      :per-page="perPage"
      v-model="currentPage"
      size="sm"
      align="center"
    />
    <div v-if="showPagination" class="text-center text-muted">
      <small>{{ currentPageCount }} / {{ totalRows }}</small>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { RequestQueryBuilder, CondOperator } from '@dataui/crud-request';

export default {
  props: {
    partner: Object,
  },
  data() {
    return {
      loading: false,
      fields: [
        {
          label: 'Job',
          key: 'job.name',
        },
        {
          label: 'Reason',
          key: 'reasonCategory',
        },
        {
          label: 'Created date',
          key: 'createdAt',
          sortable: true,
          formatter: (value) => (value ? this.$moment(value).format('L') : '-'),
        },
        {
          label: 'Actions',
          key: '_actions',
          class: 'text-right',
        },
      ],
      rejections: [],
      currentPage: 1,
      currentPageCount: null,
      perPage: 5,
      totalRows: 0,
      sortBy: null,
      sortDir: false,
      job: {
        options: [],
        loading: false,
        error: false,
        selected: [],
      },
      rejectReasons: [],
      rejectReason: null,
      rejectReasonText: null,
    };
  },
  computed: {
    showPagination() {
      return this.totalRows > this.perPage;
    },
    selectecJobs() {
      return this.job.selected.map((job) => job.value);
    },
    useRejectReasonText() {
      return this.rejectReason == 'other_reason';
    },
  },
  methods: {
    itemsProvider() {
      const queryString = RequestQueryBuilder.create({
        filter: {
          field: 'partner.id',
          operator: CondOperator.IN,
          value: this.partner.account.id,
        },
        sort: !this.sortBy
          ? null
          : [{ field: this.sortBy, order: this.sortDir ? 'DESC' : 'ASC' }],
      }).query();
      return axios
        .get(`/data-api/rejected-job?${queryString}`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return [];
        });
    },
    removeRejection(id) {
      if (confirm(this.$t('partner.reject.remove.confirm'))) {
        axios
          .delete(`/data-api/rejected-job/${id}`)
          .then(() => {
            this.$refs.rejectionsTable.refresh();
          })
          .catch(() => {
            alert(this.$t('partner.reject.remove.error'));
          });
      }
    },
    initJobOptions() {
      this.job.loading = true;
      axios
        .get(`/api/admin/offers-for-partner/${this.partner.account.id}/50000`)
        .then((response) => {
          this.job.options = response.data
            .filter((offer) => {
              return (
                offer.in_radius &&
                !offer.in_rejected_jobs &&
                !offer.in_rejected_brands &&
                !offer.in_rejected_opportunities
              );
            })
            .map((offer) => ({
              value: offer.job.api_id__c,
              name: `${offer.job.name} - ${offer.opportunity.name}`,
            }));
          this.job.error = false;
        })
        .catch(() => {
          this.job.error = true;
        })
        .then(() => {
          this.job.loading = false;
        });
    },
    jobsFind(query) {
      this.job.loading = true;
      axios
        .get(`/api/admin/jobs`, {
          params: {
            search: query,
            include_test_jobs: true,
          },
        })
        .then((response) => {
          this.job.options = response.data.data.map((item) => ({
            value: item.job.api_id,
            name: `${item.job.name} - ${item.opportunity.name}`,
          }));
          this.job.error = false;
        })
        .catch(() => {
          this.job.error = true;
        })
        .then(() => {
          this.job.loading = false;
        });
    },
    fetchRejectReasons() {
      if (this.rejectReasons.length == 0) {
        axios
          .get(
            `/data-api/sf-picklist?api_names=Reject_Job_Reasons&values_only=true`,
          )
          .then((response) => {
            this.rejectReasons =
              response.data.Reject_Job_Reasons.Metadata.customValue.map(
                (item) => ({
                  text: item.label,
                  value: item.valueName,
                }),
              );
          });
      }
    },
    rejectJobs() {
      let requests = [];
      this.selectecJobs.forEach((job_api_id) => {
        requests.push(
          axios.post(`/data-api/rejected-job`, {
            jobApiId: job_api_id,
            partnerSfid: this.partner.account.id,
            reasonCategory: this.rejectReason,
            ...(this.useRejectReasonText
              ? { reasonText: this.rejectReasonText }
              : null),
          }),
        );
      });
      Promise.all(requests)
        .then(() => {
          alert(this.$t('partner.reject.success'));
          this.$refs.rejectionsTable.refresh();
        })
        .catch(() => {
          alert(this.$t('partner.reject.error'));
        });
    },
    openRejectionModal() {
      this.job.selected = [];
      this.fetchRejectReasons();
      this.initJobOptions();
      this.$refs.addRejectionModal.show();
    },
  },
};
</script>
