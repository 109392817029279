<template>
  <div>
    <b-table
      ref="partnersTable"
      small
      stacked="md"
      :items="itemsProvider"
      :fields="fields"
      :busy.sync="loading"
    >
      <template #cell(in_radius)="{ item }">
        <i
          v-if="item.in_radius === null"
          class="material-icons text-warning"
          v-b-tooltip.hover
          title="Missing coordinates"
        >
          warning
        </i>
        <bool-indicator v-else :value="item.in_radius" />
      </template>
      <template #head(_rejections)="{ label }">
        <span v-b-tooltip.hover title="(Job / Brand / Opportunity / Service)">
          {{ label }}
          <sup>?</sup>
        </span>
      </template>
      <template #cell(_rejections)="{ item }">
        <bool-indicator
          :value="
            item.in_rejected_jobs ||
            item.in_rejected_brands ||
            item.in_rejected_opportunities ||
            item.in_rejected_services
          "
          :reverseColors="true"
        />
        (
        {{ item.in_rejected_jobs | bool_display }} /
        {{ item.in_rejected_brands | bool_display }} /
        {{ item.in_rejected_opportunities | bool_display }} /
        {{ item.in_rejected_services | bool_display }}
        )
        <b-button
          v-if="!item.in_rejected_jobs"
          size="sm"
          variant="outline-primary"
          @click="openRejectModal(item.job)"
        >
          Reject
        </b-button>
      </template>
    </b-table>
    <b-spinner variant="secondary" v-if="loading" />
    <b-modal
      ref="addRejectionModal"
      :title="
        $t('partner.reject.title') + (rejectJob ? `: ${rejectJob.name}` : '')
      "
      centered
      :ok-title="$t('partner.reject.button')"
      :ok-disabled="rejectReason === null"
      @ok="rejectJobs"
    >
      <b-form-select
        v-model="rejectReason"
        :options="rejectReasons"
        class="mb-2"
      >
        <template slot="first">
          <option :value="null" disabled>
            -- {{ $t('partner.reject.select-placeholder') }} --
          </option>
        </template>
      </b-form-select>
      <b-form-textarea
        v-if="useRejectReasonText"
        v-model="rejectReasonText"
        :placeholder="$t('partner.reject.text-placeholder')"
        rows="3"
        max-rows="6"
        class="mb-2"
      ></b-form-textarea>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapFilters } from '@gid/vue-common/store/utils';

export default {
  props: {
    partner: Object,
  },
  data() {
    return {
      fields: [
        {
          label: 'Job',
          key: 'job.name',
        },
        {
          label: 'Customer',
          key: 'customer.name',
        },
        {
          label: 'Opportunity',
          key: 'opportunity.name',
        },
        {
          label: 'In Radius',
          key: 'in_radius',
          class: 'text-center',
        },
        {
          label: 'In Rejections',
          key: '_rejections',
          class: 'text-center',
        },
      ],
      loading: false,
      rejectReasons: [],
      rejectReason: null,
      rejectReasonText: null,
      rejectJob: null,
    };
  },
  computed: {
    ...mapFilters({
      moduleName: 'partners',
      states: [
        'search',
        'status',
        'owner',
        'useAdvancedSearch',
        'advancedSearch',
      ],
      setCallback: 'filterUpdated',
    }),
    getRadiusValue() {
      return Object.keys(this.filterAdvancedSearch).map((key) => {
        return this.filterAdvancedSearch[key].filter(
          (el) => el['field'] === 'point_with_radius',
        )[0];
      });
    },
    useRejectReasonText() {
      return this.rejectReason == 'other_reason';
    },
  },
  filters: {
    bool_display(value) {
      return value ? '✓' : '×';
    },
  },
  methods: {
    itemsProvider() {
      const radius = this.getRadiusValue[0]
        ? this.getRadiusValue[0]['value']['radius']
        : 50000;
      return axios
        .get(
          `/api/admin/offers-for-partner/${this.partner.account.id}/${radius}`,
        )
        .then((response) => {
          const items = response.data.map((offer) => ({
            ...offer,
            _rowVariant:
              offer.in_radius &&
              !offer.in_rejected_jobs &&
              !offer.in_rejected_brands &&
              !offer.in_rejected_opportunities &&
              !offer.in_rejected_services
                ? 'success'
                : null,
          }));
          return items;
        })
        .catch(() => {
          return [];
        });
    },
    fetchRejectReasons() {
      if (this.rejectReasons.length == 0) {
        axios
          .get(
            `/data-api/sf-picklist?api_names=Reject_Job_Reasons&values_only=true`,
          )
          .then((response) => {
            this.rejectReasons =
              response.data.Reject_Job_Reasons.Metadata.customValue.map(
                (item) => ({
                  text: item.label,
                  value: item.valueName,
                }),
              );
          });
      }
    },
    openRejectModal(job) {
      this.rejectJob = job;
      this.rejectReason = null;
      this.fetchRejectReasons();
      this.$refs.addRejectionModal.show();
    },
    rejectJobs() {
      axios
        .post(`/data-api/rejected-job`, {
          jobApiId: this.rejectJob.api_id__c,
          partnerSfid: this.partner.account.id,
          reasonCategory: this.rejectReason,
          ...(this.useRejectReasonText
            ? { reasonText: this.rejectReasonText }
            : null),
        })
        .then(() => {
          alert(this.$t('partner.reject.success'));
          this.$refs.partnersTable.refresh();
        })
        .catch(() => {
          alert(this.$t('partner.reject.error'));
        });
    },
  },
};
</script>
