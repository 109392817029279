var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('b-form-input',{attrs:{"type":"search","placeholder":"Job/Credit note/Invoice number"},model:{value:(_vm.filters.number),callback:function ($$v) {_vm.$set(_vm.filters, "number", $$v)},expression:"filters.number"}})],1)],1),_c('b-col',[_c('b-form-group',[_c('b-form-datepicker',{attrs:{"placeholder":"Date from","locale":_vm.locale,"date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },"today-button":"","reset-button":"","close-button":""},model:{value:(_vm.filters.dateFrom),callback:function ($$v) {_vm.$set(_vm.filters, "dateFrom", $$v)},expression:"filters.dateFrom"}})],1)],1),_c('b-col',[_c('b-form-group',[_c('b-form-datepicker',{attrs:{"placeholder":"Date until","locale":_vm.locale,"date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },"today-button":"","reset-button":"","close-button":""},model:{value:(_vm.filters.dateTo),callback:function ($$v) {_vm.$set(_vm.filters, "dateTo", $$v)},expression:"filters.dateTo"}})],1)],1),_c('b-col',[_c('b-form-group',[_c('multiselect',{attrs:{"options":_vm.filterStatusOptions,"searchable":true,"placeholder":"Status"},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)],1),_c('b-col',[_c('b-form-group',[_c('multiselect',{attrs:{"options":_vm.filterDocumentTypes,"searchable":true,"placeholder":"Document type"},model:{value:(_vm.filters.docType),callback:function ($$v) {_vm.$set(_vm.filters, "docType", $$v)},expression:"filters.docType"}})],1)],1)],1),_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","show-empty":"","thead-class":"bg-white align-middle","thead-tr-class":"bg-white","tbody-class":"bg-white","items":_vm.items,"fields":_vm.fields,"busy":_vm.loading},scopedSlots:_vm._u([{key:"cell(issueDate)",fn:function({ item }){return [_c('i',[_vm._v(_vm._s(_vm._f("moment")(item.issueDate,'L')))])]}},{key:"cell(actions)",fn:function({ item }){return [_c('a',{attrs:{"target":"_blank","href":_vm._f("jwtToken")(`/documents-api/file${item.path}`)}},[_c('i',{staticClass:"material-icons download-button"},[_vm._v("download")])])]}}])}),_c('b-pagination',{staticClass:"my-3",attrs:{"total-rows":_vm.pagination.totalRows,"per-page":_vm.pagination.perPage,"align":"center"},on:{"input":_vm.refreshDocumentList},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }