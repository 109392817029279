<template>
  <div class="job-contacts-table">
    <b-table striped :items="partnerJobs" :fields="fields" :busy="loadingData">
      <template #head(selection)>
        <b-form-checkbox
          :checked="areAllSelected"
          @change="selectAllTableRowsOnPage"
          name="select-all"
        />
      </template>

      <template #cell(selection)="{ item }">
        <b-form-checkbox
          :checked="isSingleElementSelected(item.api_id)"
          @change="selectSingleTableRow(item.api_id)"
          name="checkbox-1"
        />
      </template>

      <template #cell(name)="{ item }">
        <a
          :label="item.job_name"
          :href="`/jobs/${item.job_sfid}`"
          class="text-nowrap"
          :target="'_blank'"
        >
          {{ item.job_name }}
        </a>
      </template>

      <template #cell(opportunity)="{ item }">
        {{ item.opportunity_name }}
      </template>

      <template #cell(location)="{ item }">
        <span>
          {{ item.cust_shipping_city }},
          {{ item.cust_shipping_postal_code }}
        </span>
      </template>

      <template #cell(distance)="{ item }">
        <span>{{ (item.distance / 1000).toFixed(2) }} km</span>
      </template>

      <template #cell(price)="{ item }">
        <span :title="$t('price.list')" v-b-tooltip.hover>
          {{ item.price_purchasing_sum | currency }}
        </span>
        <span v-if="isMarginNegative(item)" class="text-danger">
          {{ $t('negative-margin') }}
        </span>
      </template>

      <template #cell(job-status)="{ item }">
        <span>
          {{ showJobStatus(item.job_status) }}
          <br />
          {{ latestStatusChangeFormatted(item) }}
        </span>
      </template>

      <template #cell(sla)="{ item }">
        <DaysSinceLastChange :days="item.days_since_last_status_change" />
      </template>

      <!-- <template v-if="showActions" #cell(actions)="{}">
        <b-row>
          <span
            title="Reject"
            v-b-tooltip.hover
            class="material-icons text-decoration-none text-danger p-2 rounded font-weight-bold"
            @click="onRejectJob"
            style="cursor: pointer"
          >
            close
          </span>
          <span
            title="Accept"
            v-b-tooltip.hover
            class="material-icons text-decoration-none text-success p-2 rounded font-weight-bold"
            @click="onAcceptJob"
            style="cursor: pointer"
          >
            done
          </span>
        </b-row>
      </template> -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <b-row class="justify-content-between">
      <b-col cols="6">
        <b-row class="justify-content-center">
          <b-pagination
            v-if="total > 0"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            @change="onPageChange"
          />
        </b-row>
      </b-col>

      <b-col cols="6">
        <b-row class="justify-content-center">
          <b-button
            variant="primary"
            class="d-flex align-items-center"
            @click="showPartnerCallModal = true"
          >
            <span
              class="material-icons text-decoration-none text-white p-2 rounded"
            >
              phone
            </span>
            {{ $t('call-partner') }}
          </b-button>
          <b-button
            :disabled="selectedJobs.length === 0"
            variant="primary"
            class="ml-3 px-3"
            @click="showNewTicketModal = true"
          >
            {{ $t('tickets.new') }}
          </b-button>
        </b-row>
      </b-col>
    </b-row>
    <RejectJobModal
      :visible="showRejectJobModal"
      @close="showRejectJobModal = false"
    />
    <AcceptJobModal
      :visible="showAcceptJobModal"
      @close="showAcceptJobModal = false"
    />
    <CallContactModal
      :visible="showPartnerCallModal"
      :contactList="contacts"
      @hide="showPartnerCallModal = false"
      @call-initiated="onCallThePartner"
    />
    <live-agent-ticket-new
      :jobNames="getJobNames"
      :visible="showNewTicketModal"
      :jobContacts="jobContacts"
      :jobs="selectedJobs"
      @close="showNewTicketModal = false"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { UserRoleEnum, GeneralInvoicingTargetEnum } from '@gid/models';
import RejectJobModal from '@/components/partner/RejectJobModal.vue';
import AcceptJobModal from '@/components/partner/AcceptJobModal.vue';
import DaysSinceLastChange from '@gid/vue-common/components/DaysSinceLastChange';
import CallContactModal from '@/components/CallContactModal.vue';
import LiveAgentTicketNew from '../ticketing/LiveAgentTicketNew.vue';
import { Zammad } from '@gid/vue-common/components/ticketing/interface/ticketing';

export default {
  components: {
    RejectJobModal,
    AcceptJobModal,
    DaysSinceLastChange,
    CallContactModal,
    LiveAgentTicketNew,
  },
  props: {
    partnerId: String,
    contacts: Array,
    searchTerm: String,
    selectedJobs: Array,
    endpoint: String,
    showActions: Boolean,
  },
  data() {
    return {
      localSelectedJobs: [],
      perPage: 5,
      currentPage: 1,
      jobStatusDescriptionsLangs: [],
      loadingData: false,
      partnerJobs: [],
      total: 0,
      showRejectJobModal: false,
      showAcceptJobModal: false,
      showPartnerCallModal: false,
      showNewTicketModal: false,
      jobContacts: [],
    };
  },
  async created() {
    this.localSelectedJobs = this.selectedJobs;
    this.jobContacts = await this.getJobContacts();
    this.$emit('tab-change');
    this.loadStatusDescriptions();
    this.preformJobSearch({
      page: this.currentPage - 1,
      partnerId: this.partnerId,
      search: '',
    });
  },
  computed: {
    ...mapGetters(['locale']),
    fields() {
      const baseFields = [
        { key: 'selection', label: this.$t('selected') },
        { key: 'name', label: this.$t('job.table.heading.job') },
        { key: 'opportunity', label: this.$t('job.table.heading.opportunity') },
        { key: 'location', label: this.$t('job.table.heading.location') },
        { key: 'distance', label: this.$t('job.table.heading.distance') },
        { key: 'price', label: this.$t('job.table.heading.price') },
        { key: 'job-status', label: this.$t('job.table.heading.job-status') },
        { key: 'sla', label: this.$t('job.table.heading.sla') },
      ];

      // if (this.showActions) {
      //   baseFields.push({
      //     key: 'actions',
      //     label: this.$t('job.table.heading.actions'),
      //   });
      // }

      return baseFields;
    },
    areAllSelected() {
      return this.partnerJobs.every((elem) =>
        this.localSelectedJobs.some((job) => job.api_id === elem.api_id),
      );
    },
    isSingleElementSelected() {
      return (rowId) =>
        this.localSelectedJobs.some((job) => job.api_id === rowId);
    },
    isMarginNegative() {
      return (item) => item.revenue - item.costs < 0;
    },
    showJobStatus() {
      return (status) => {
        const foundLang = this.jobStatusDescriptionsLangs
          ? this.jobStatusDescriptionsLangs.find(
              (lang) => lang.jobStatus === status,
            )
          : null;
        if (foundLang) {
          return foundLang.jobStatusDescriptionLangs[0].nameGid;
        }
      };
    },
    latestStatusChangeFormatted() {
      return (item) => {
        return this.$moment(
          item.latest_action_date ?? item.created_date,
        ).format('L');
      };
    },
    getJobNames() {
      return this.selectedJobs.map((job) => job.job_name);
    },
  },
  methods: {
    /** preform simple query to fetch all jobs of partner
     * @param {number} page
     * @param {number} partnerId
     * @param {string} search
     * **/
    async preformJobSearch({ page, partnerId, search }) {
      this.loadingData = true;
      let pageQuery = `/data-api/entity/job/contact/${partnerId}/${this.endpoint}?page=${page}`;
      if (search) {
        pageQuery = pageQuery.concat(`&search=${search}`);
      }

      try {
        const { data } = await axios.get(pageQuery);
        this.partnerJobs = data.jobs;
        this.total = data.total;
      } catch (error) {
        this.$bvToast.toast(
          error.response?.data?.error ||
            error?.response?.statusText ||
            'Something went wrong!',
          {
            title: 'Error',
            variant: 'danger',
            noAutoHide: true,
          },
        );
      }

      this.loadingData = false;
    },
    selectAllTableRowsOnPage(isSelected) {
      if (isSelected) {
        const filteredJobs = this.partnerJobs.filter(
          (job) =>
            !this.localSelectedJobs.some((elem) => elem.api_id === job.api_id),
        );
        this.localSelectedJobs.push(...filteredJobs);
      } else {
        this.localSelectedJobs = this.localSelectedJobs.filter(
          (job) => !this.partnerJobs.some((elem) => elem.api_id === job.api_id),
        );
      }

      this.$emit('job-selected', this.localSelectedJobs);
    },
    selectSingleTableRow(rowId) {
      if (this.localSelectedJobs.some((job) => job.api_id === rowId)) {
        this.localSelectedJobs = this.localSelectedJobs.filter(
          (job) => job.api_id !== rowId,
        );
      } else {
        this.localSelectedJobs = [
          ...this.localSelectedJobs,
          this.partnerJobs.find((job) => job.api_id === rowId),
        ];
      }
      this.$emit('job-selected', this.localSelectedJobs);
    },
    onPageChange(page) {
      const nextPage = page - 1;
      this.preformJobSearch({
        page: nextPage,
        partnerId: this.partnerId,
        search: this.searchTerm ? this.searchTerm : null,
      });
      this.currentPage = page;
    },
    async loadStatusDescriptions() {
      const { data } = await axios.get(
        'data-api/entity/job-status-description',
      );
      this.jobStatusDescriptionsLangs = data;
    },
    async getJobContacts() {
      let contacts = await Zammad.fetchContacts([this.partnerId]);
      contacts = contacts
        .filter((c) => c.email && !c.email.match(/(@getitdone.[rocks,co])\w+/g))
        .map((contact) => {
          return {
            jobRole: GeneralInvoicingTargetEnum.PARTNER,
            ...contact,
          };
        });

      return contacts;
    },
    onCallThePartner(contactPhone) {
      this.callPartner(contactPhone); // here we call the partner
      if (this.selectedJobs.length > 0) this.createMultipleComments(); // make api call to create comment tha call is initiated
      this.onClose();
    },
    /** initiate call to partner phone **/
    callPartner(partnerPhone) {
      const url = `tel:${partnerPhone}`;
      window.open(url);
    },
    /** Make a comments on all selected job, that call is initiated  **/
    async createMultipleComments() {
      const comments = this.localSelectedJobs.map((job) => {
        return {
          actionType: 'phone',
          jobApiId: job.api_id,
          message: this.$t('call.partner'),
          visibleForRoles: [UserRoleEnum.ADMIN, UserRoleEnum.BRAND],
        };
      });
      try {
        const { data } = await axios.post(`data-api/comment/bulk`, {
          bulk: comments,
        });
      } catch (error) {
        this.$bvToast.toast(
          error.response?.data?.error ||
            error?.response?.statusText ||
            'Something went wrong!',
          {
            title: 'Error',
            variant: 'danger',
            noAutoHide: true,
          },
        );
      }
    },
    onClose() {
      this.$emit(
        'on-partner-call',
        this.localSelectedJobs.map((job) => job.api_id),
      );
      this.localSelectedJobs = [];
      this.currentPage = 1;
    },
    onRejectJob() {
      this.showRejectJobModal = true;
    },
    onAcceptJob() {
      this.showAcceptJobModal = true;
    },
  },
  watch: {
    searchTerm: function (val) {
      this.preformJobSearch({
        page: 0,
        partnerId: this.partnerId,
        search: val ? val : null,
      });
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.b-table th {
  white-space: nowrap;
}
</style>
