
import {
  CondOperator,
  CreateQueryParams,
  RequestQueryBuilder,
  SConditionAND,
  SFields,
} from '@dataui/crud-request';
import {
  Account,
  AccountingDocumentStatusEnum,
  AccountingDocumentTypeEnum,
} from '@gid/models';
import Api from '@gid/vue-common/api';
import { CrudListFetchPayload } from '@gid/vue-common/store/shared/crud-list.module';
import { BButton, BCard, BFormCheckbox, BTable } from 'bootstrap-vue';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'PartnerAccounting',
  components: {
    BTable,
    BButton,
    BCard,
    BFormCheckbox,
  },
  props: {
    partner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      crudEndpoint: '/documents-api/accounting/documents',
      sortBy: 'issueDate',
      sortDirection: 'DESC',
      fields: [
        { key: 'number', label: 'Job Number' },
        { key: 'documentType', label: 'Document Type' },
        { key: 'recipients', label: 'Recipients' },
        { key: 'issueDate', label: 'Issue date' },
        { key: 'documentNumber', label: 'Document number' },
        { key: 'totalAmount', label: 'Total amount' },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: '', class: 'text-center' },
      ],
      filters: {
        number: '',
        docType: null,
        recipients: null,
        dateFrom: null,
        dateTo: null,
        documentNumber: null,
        totalAmount: null,
        status: null,
      },
      pagination: {
        totalRows: null,
        perPage: 25,
        currentPage: 1,
        currentPageCount: 0,
        numberOfPages: 1,
      },
      loading: true,
    };
  },
  created() {
    this.refreshDocumentList();
  },
  computed: {
    ...mapGetters(['locale', 'access_token']),
    filterStatusOptions() {
      return Object.values(AccountingDocumentStatusEnum);
    },
    filterDocumentTypes() {
      return Object.values(AccountingDocumentTypeEnum);
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.refreshDocumentList();
      },
    },
  },
  methods: {
    buildSearch() {
      const search: Array<SFields | SConditionAND> = [];

      const searchTerm = this.filters.number.trim();
      if (searchTerm) {
        search.push({
          $or: [
            {
              number: {
                [CondOperator.CONTAINS_LOW]: searchTerm,
              },
            },
            {
              'jobs.name': {
                [CondOperator.CONTAINS_LOW]: searchTerm,
              },
            },
            {
              cancellationNumber: {
                [CondOperator.CONTAINS_LOW]: searchTerm,
              },
            },
          ],
        });
      }
      if (this.filters.dateFrom) {
        search.push({
          issueDate: {
            $gte: this.filters.dateFrom,
          },
        });
      }
      if (this.filters.dateTo) {
        search.push({
          issueDate: {
            $lte: this.filters.dateTo,
          },
        });
      }
      if (this.filters.status) {
        search.push({
          status: {
            $eq: this.filters.status,
          },
        });
      }
      if (this.filters.docType) {
        search.push({
          type: {
            $eq: this.filters.docType,
          },
        });
      }

      search.push({
        'target.id': {
          $eq: this.partner.account.id,
        },
      });

      return {
        search: {
          $and: search,
        },
      };
    },

    refreshDocumentList() {
      const { search } = this.buildSearch();

      const query: CreateQueryParams = {
        page: this.pagination.currentPage,
        limit: this.pagination.perPage,
      };
      this.fetchItems({
        query,
        search,
      });
    },

    async fetchItems(payload: CrudListFetchPayload) {
      const query: CreateQueryParams = { ...payload.query };
      query.sort = [this.sortBy, this.sortDirection ? 'DESC' : 'ASC'];

      if (!payload.noPagination) {
        query.page = this.pagination.currentPage;
        query.limit = this.pagination.perPage;
      }
      const qb = RequestQueryBuilder.create(query);
      if (payload.search) {
        qb.search(payload.search);
      }
      const queryString = qb.query();
      this.loading = true;
      return Api.get(`${this.crudEndpoint}?${queryString}`, {
        autoCancelScope: this.crudEndpoint,
      })
        .then((response) => {
          const { data, page, pageCount, total } = response.data;
          this.items = data.map((item: any) => ({
            number: item.jobs[0].name,
            status: item.status,
            documentType: item.type,
            path: item.path,
            issueDate: item.issueDate,
            documentNumber: item.number,
            totalAmount: item.totalAmount,
            recipients: item.target.emailForInvoices,
          }));
          this.pagination.currentPage = page;
          this.pagination.numberOfPages = pageCount;
          this.pagination.totalRows = total;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
