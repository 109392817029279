<template>
  <b-modal
    :visible="value"
    size="xl"
    scrollable
    hide-footer
    centered
    no-close-on-backdrop
    no-close-on-esc
    @input="onClose"
    @close="onClose"
  >
    <template #modal-title>
      {{ partnerName }}
      <b-badge :variant="partnerExperienceLevel(completedJobs).variant">
        {{ partnerExperienceLevel(completedJobs).text }}
      </b-badge>
    </template>

    <b-row>
      <b-col cols="5">
        <b-form-input
          :value="searchTerm"
          @input="performSearch"
          :placeholder="$t('type-to-search')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-3 mt-4">
      <b-col>
        {{ $t('selected-jobs-title', { count: selectedJobsCount }) }}
      </b-col>
    </b-row>

    <b-card no-body>
      <b-tabs card active-nav-item-class="font-weight-bold">
        <b-tab
          :title="$t('job.tabs.shown')"
          class="p-0"
          lazy
          :active="getActiveTab('shown')"
        >
          <PartnerContactJobsTable
            :partnerId="partnerId"
            :contacts="contacts"
            :searchTerm="searchTerm"
            :selectedJobs="selectedJobs"
            :showActions="false"
            endpoint="shown-jobs"
            @on-partner-call="onPartnerCall"
            @tab-change="searchTerm = ''"
            @job-selected="handleJobSelection"
          />
        </b-tab>
        <b-tab
          :title="$t('job.tabs.potential')"
          class="p-0"
          lazy
          :active="getActiveTab('potential')"
        >
          <PartnerContactJobsTable
            :partnerId="partnerId"
            :contacts="contacts"
            :searchTerm="searchTerm"
            :selectedJobs="selectedJobs"
            :showActions="true"
            endpoint="potential-jobs"
            @on-partner-call="onPartnerCall"
            @tab-change="searchTerm = ''"
            @job-selected="handleJobSelection"
          />
        </b-tab>
        <b-tab
          :title="$t('job.tabs.active')"
          class="p-0"
          lazy
          :active="getActiveTab('active')"
        >
          <PartnerContactJobsTable
            :partnerId="partnerId"
            :contacts="contacts"
            :searchTerm="searchTerm"
            :selectedJobs="selectedJobs"
            :showActions="false"
            endpoint="active-jobs"
            @on-partner-call="onPartnerCall"
            @tab-change="searchTerm = ''"
            @job-selected="handleJobSelection"
          />
        </b-tab>
        <b-tab
          :title="$t('job.tabs.done')"
          class="p-0"
          lazy
          :active="getActiveTab('completed')"
        >
          <PartnerContactJobsTable
            :partnerId="partnerId"
            :contacts="contacts"
            :searchTerm="searchTerm"
            :selectedJobs="selectedJobs"
            :showActions="false"
            endpoint="completed-jobs"
            @on-partner-call="onPartnerCall"
            @tab-change="searchTerm = ''"
            @job-selected="handleJobSelection"
          />
        </b-tab>
        <b-tab
          :title="$t('job.tabs.rejected')"
          class="p-0"
          lazy
          :active="getActiveTab('rejected')"
        >
          <PartnerContactJobsTable
            :partnerId="partnerId"
            :contacts="contacts"
            :searchTerm="searchTerm"
            :selectedJobs="selectedJobs"
            :showActions="false"
            endpoint="rejected-jobs"
            @on-partner-call="onPartnerCall"
            @tab-change="searchTerm = ''"
            @job-selected="handleJobSelection"
          />
        </b-tab>
        <b-tab
          :title="$t('job.tabs.cancelled')"
          class="p-0"
          lazy
          :active="getActiveTab('cancelled')"
        >
          <PartnerContactJobsTable
            :partnerId="partnerId"
            :contacts="contacts"
            :searchTerm="searchTerm"
            :selectedJobs="selectedJobs"
            :showActions="false"
            endpoint="cancelled-jobs"
            @on-partner-call="onPartnerCall"
            @tab-change="searchTerm = ''"
            @job-selected="handleJobSelection"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { _partnerExperienceLevel } from '@gid/vue-common/utils';
import PartnerContactJobsTable from '@/components/partner/PartnerContactJobsTable.vue';

export default {
  components: {
    PartnerContactJobsTable,
  },
  props: {
    partnerId: String,
    partnerName: String,
    contacts: Array,
    completedJobs: Number,
    value: Boolean,
    activeTab: String,
  },
  data() {
    return {
      searchTerm: '',
      currentPage: 1,
      selectedJobs: [], // track ids of selected jobs per page
    };
  },
  computed: {
    ...mapGetters(['locale']),
    selectedJobsCount() {
      return this.selectedJobs.length;
    },
    partnerExperienceLevel() {
      return _partnerExperienceLevel;
    },
  },
  methods: {
    /** Emit action to Parent cmp to Trigger API call when user types in search bar  **/
    performSearch: debounce(function (value) {
      this.searchTerm = value;
    }, 600),
    /** Partner Modal close action **/
    onClose() {
      this.$emit('close', {
        selectedJobsIds: this.selectedJobs.map((job) => job.api_id),
        isCalled: false,
      });
      this.close();
    },
    getActiveTab(tabName) {
      return this.activeTab === tabName;
    },
    onPartnerCall(selectedJobsIds) {
      this.close();
      this.$emit('close', { selectedJobsIds, isCalled: true });
    },
    close() {
      this.selectedJobs = [];
      this.currentPage = 1;
      this.searchTerm = null;
    },
    handleJobSelection(val) {
      this.selectedJobs = val;
    },
  },
};
</script>
